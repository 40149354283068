import React from 'react'
import {Table} from 'react-bootstrap'

function CoreSkills(){
    return(
        <div style={{display:"flex", flexDirection:window.innerWidth<600?"column":"row", margin:"20px"}}>
        <Table style={{color:"gold", fontWeight:700, maxWidth:"450px"}}>
            <tbody>
            <tr>
                <td>
                  Create engineering applications for web and mobile platforms
                </td>
              </tr>
              <tr>
                <td>
                  Transform engineering applications in Matlab, Simulink, C++, Python for use over web and mobile platforms
                </td>
              </tr>
              <tr>
                <td>
                  Full stack project using MERN stack(Mongodb, Express, React and Nodejs)
                </td>
              </tr>
              <tr>
                <td>
                  Frontend development using React, html and css
                </td>
              </tr>
              <tr>
                <td>
                  Mobile Application Development for IOS using React Native and Objective-C
                </td>
              </tr>
              <tr>
                <td>
                  Software Version Control 
                </td>
              </tr>
              <td>
                <tr>
                  Web applications testing and deployment using Heroku, AWS and Azure
                </tr>
              </td>
            </tbody>
          </Table>
          <div style={{display:"flex", flexDirection:window.innerWidth < 600? "row" : "column", alignItems:"center", flexWrap:"wrap", marginLeft:"20px", flexGrow:1}}>
          <img src={require('../../images/python_logo.png')} width="150px" height="50px" style={{display:"block", margin:"5px"}}/>
          <img src={require('../../images/javascript_logo.png')} width="100px" height="100px" style={{display:"block", margin:"5px"}}/>
          <img src={require('../../images/react_logo.svg')} width="100px" height="100px" style={{display:"block", margin:"5px"}}/>
          <img src={require('../../images/mongodb_logo.png')} width="150px" height="50px" style={{display:"block", margin:"5px"}}/>
          <img src={require('../../images/postgres_logo.svg')} width="100px" height="100px" style={{display:"block", margin:"5px"}}/>
          <img src={require('../../images/nodejs_logo.svg')} width="150px" height="100px" style={{display:"block", margin:"5px"}}/>
          <img src={require('../../images/cpp_logo.png')} width="75px" height="75px" style={{display:"block", margin:"5px"}}/>
          <img src={require('../../images/github_logo.png')} width="150px" height="50px" style={{display:"block", margin:"5px"}}/>
        </div>
        </div>
    )
}

export default CoreSkills