import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

export default function WhatWeDo(){
    return(
        <Container style={{marginLeft:"20px", display:"inline-block", marginTop:"20px"}}>
          <Row>
            <Col>
              <Row style={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom:"20px"}}>
                <img src={require('../../images/single_page.svg')} width="100" height="100"/>
                <p style={{ display:"inline-block", marginTop:"10px", marginLeft:"10px", fontSize:"20px", fontWeight:600, color:"#0080ff"}}>Single Page Web Applications</p>
              </Row>
              <Row style={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom:"20px"}}>
                <img src={require('../../images/full_stack_project.svg')} width="100" height="100"/>
                <p style={{ display:"inline-block", marginTop:"10px", marginLeft:"10px", fontSize:"20px", fontWeight:600, color:"#0080ff"}}>Full Stack Projects</p>
              </Row>
              <Row style={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom:"20px"}}>
                <img src={require('../../images/module_development.svg')} width="100" height="100"/>
                <p style={{ display:"inline-block", marginTop:"10px", marginLeft:"10px", fontSize:"20px", fontWeight:600, color:"#0080ff"}}>Module Development</p>
              </Row>
              <Row style={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom:"20px"}}>
                <img src={require('../../images/test_development.svg')} width="100" height="100"/>
                <p style={{ display:"inline-block", marginTop:"10px", marginLeft:"10px", fontSize:"20px", fontWeight:600, color:"#0080ff"}}>Test Development</p>
              </Row>
            </Col>
            <Col>
              <Row style={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom:"20px"}}>
                <img src={require('../../images/data_analysis.svg')} width="100" height="100"/>
                <p style={{ display:"inline-block", marginTop:"10px", marginLeft:"10px", fontSize:"20px", fontWeight:600, color:"#0080ff"}}>Data Analysis</p>
              </Row>
              <Row style={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom:"20px"}}>
                <img src={require('../../images/signal_analysis.svg')} width="100" height="100"/>
                <p style={{ display:"inline-block", marginTop:"10px", marginLeft:"10px", fontSize:"20px", fontWeight:600, color:"#0080ff"}}>Signal Analysis</p>
              </Row>
              <Row style={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom:"20px"}}>
                <img src={require('../../images/modeling_and_simulation.svg')} width="100" height="100"/>
                <p style={{ display:"inline-block", marginTop:"10px", marginLeft:"10px", fontSize:"20px", fontWeight:600, color:"#0080ff"}}>Modeling and Simulation</p>
              </Row>
              <Row style={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom:"20px"}}>
                <img src={require('../../images/automotive_software_development.svg')} width="100" height="100"/>
                <p style={{ display:"inline-block", marginTop:"10px", marginLeft:"10px", fontSize:"20px", fontWeight:600, color:"#0080ff"}}>Automotive Software Development</p>
              </Row>
            </Col>
          </Row>
        </Container>
    )
}