import React, {useState} from 'react'
import {Container, Row, Card, Button, ButtonGroup} from 'react-bootstrap'

function RecentProjects(){
    const [projectIndex, setProjectIndex] = useState(0)

    const covid_india_project = 
      <Card style={{width:"800px", maxWidth:window.innerWidth-100, display:"flex", flexDirection:"column"}}>
        {window.innerWidth < 600? 
        <Card.Img src={require('../../images/covid_india_map_mobile.png')} width={window.innerWidth} height={window.innerHeight*0.5} alt='covid india map image'/>:
        <Card.Img src={require('../../images/covid_india_map.png')} alt="covid india map image"/>}
        <Card.Body>
          <Card.Title>Covid India Map</Card.Title>
          <Card.Text>
            Web Application for tracking the spread of COVID-19 in India. 
            It has charts for tracking the cumulative and daily confirmed, recovered and deceased cases at national and state levels. 
            The live map allows viewing data for individual cities.<br/>
            <a href="https://www.covidindiamap.com/" target="_blank">Visit</a>
          </Card.Text>
        </Card.Body>
      </Card>

    const play_last_card_project = 
        <Card style={{width:"800px", maxWidth:window.innerWidth-100, display:"flex", flexDirection:window.innerWidth<600?'column':'row'}}>
            {window.innerWidth < 600? 
            <Card.Img variant="top" src={require('../../images/play_last_card_mobile.png')} height={(window.innerWidth-20)*0.75} alt='Play Last Card'/>:
            <Card.Img variant="left" src={require('../../images/play_last_card.png')} width="450px" height="600px" alt="covid india map image"/>}
            <Card.Body>
                <Card.Title>Play Last Card</Card.Title>
                <Card.Text>
                It is an online "Last Card" game portal. It allows you to connect with your family and friends and play "Last Card".<br/>
                <a href="https://www.playlastcard.com/" target="_blank">Visit</a>
                </Card.Text>
            </Card.Body>
        </Card>

    const time_series_analyzer_project = 
        <Card style={{width:"800px",maxWidth:window.innerWidth-100, display:"flex", flexDirection:window.innerWidth<600?"col":"row"}}>
          {window.innerWidth < 600? 
          <Card.Img variant="top" src={require('../../images/time_series_analyzer_mobile.png')} height={(window.innerWidth - 20)*0.75} alt='time series analyzer'/>:
          <Card.Img variant="left" src={require('../../images/time_series_analyzer.png')} width="400px" height="600px" alt="time series analyzer"/>}
        <Card.Body>
            <Card.Title>Time Series Analyzer</Card.Title>
            <Card.Text>
            Web based tool to visualize time series data obtained from sensors. Allows for signal processing such as moving window average, linear transformation and analysis techniques such Fourier transform, histogram, x-y plot.<br/>
            <a href="https://timeseriesanalyzer.herokuapp.com/" target="_blank">Visit</a>
            </Card.Text>
        </Card.Body>
        </Card>

    const listOfProjects = [covid_india_project, play_last_card_project, time_series_analyzer_project]

    const handleIndex = (event) => {
      const value = event.target.name === 'next'? 1: -1
      updateIndex(value)
    }

    const updateIndex = (value) => {
      let index = projectIndex
      index = index + value
      if (index >= listOfProjects.length){
        index = index - listOfProjects.length
      }
      if (index < 0){
        index = index + listOfProjects.length
      }
      setProjectIndex(index)
    }

    return (
        <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginTop:"20px", marginLeft:"10px"}}>
          <img src={require('../../images/right_arrow.svg')} style={{margin:"2px", padding:"2px", display:"inline-block", width:"25px", transform: 'rotate(180deg)'}} variant="primary" name="previous" onClick={handleIndex}/>
          {listOfProjects[projectIndex]}
          <img src={require('../../images/right_arrow.svg')} style={{margin:"2px", padding:"2px", display:"inline-block", width:"25px"}} variant="primary" name="next" onClick={handleIndex}/>
        </div>
    )
}

export default RecentProjects