import React, {Component} from 'react'
import {Form, Button} from 'react-bootstrap'
import * as emailjs from 'emailjs-com'
import Swal from 'sweetalert2'

export default class BusinessEnquiry extends Component{
    constructor(props){
        super(props)
        this.state = {
            email: '',
            name: '',
            budget: '',
            message: '',
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.resetForm = this.resetForm.bind(this)
    }
    
    handleChange(event){
        const {name, value} = event.target
        this.setState({
            [name]: value,
        })
    }

    resetForm(callback){
        this.setState({
            email: '',
            name: '', 
            budget: '',
            message: ''
        }, ()=>callback())
    }

    handleSubmit(event){
        event.preventDefault()
        emailjs.send(
            'gmail',
            'business_enquiry',
            {
                email: this.state.email,
                name: this.state.name,
                budget: this.state.budget,
                message: this.state.message,
            },
            'user_MhjI63cPXG9S4vJXeKc8Q'
        )
        .then(res =>{
            this.resetForm(()=>{
                Swal.fire({
                    title: 'Email Successfully sent',
                    icon: 'success'
                })
            })
        })
        .catch(err => {
            console.log(err)
            Swal.fire({
                title: 'Error in sending email, try again',
                icon: 'error'
            })
        })
    }

    render(){
        return(
            <Form onSubmit={this.handleSubmit} style={ window.innerWidth > 600?{minWidth:"400px", maxWidth:"500px", marginTop:"20px", marginLeft:"20px", border:"1px solid gray", borderRadius:"5px", padding:"5px"}: {width:window.innerWidth*0.9, border:"1px solid gray", marginLeft:"auto", marginRight:"auto",borderRadius:"5px", padding:"5px"}}>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="string" placeholder="name" value={this.state.name} name="name" onChange={this.handleChange} required/>
                </Form.Group>
                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="email" value={this.state.email} name="email" onChange={this.handleChange} required/>
                </Form.Group>
                <Form.Group controlId="budget">
                    <Form.Label>Budget</Form.Label>
                    <Form.Control type="number" placeholder="budget estimate in USD" value={this.state.budget} name="budget" onChange={this.handleChange} required/>
                </Form.Group>
                <Form.Group controlId="message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control  as="textarea" rows="5" placeholder="message" value={this.state.message} name="message" onChange={this.handleChange} required/>
                </Form.Group>
                <Button variant="primary" type="submit" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>Submit</Button>
            </Form>
        )
    }
}