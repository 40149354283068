import React, {useRef, useState} from 'react';
import {animated, useChain, useSpring, config} from 'react-spring'

const circleConfig = (left, radius, ref) => {

  return({
    from: {
      width: 0,
      height: 0,
      top: window.innerHeight/2 + 100,
      left: window.innerWidth/2 + left,
    },
    to: {
      width: 2*radius,
      height: 2*radius,
      top: window.innerHeight/2 + 100 - radius,
      left: window.innerWidth/2 + left - radius,
    },
    config:{duration: 500},
    ref: ref
  })
}


const AnimatedCircle = (circle, text) => {
  return(
    <animated.div style={{
      ...circle,
      position:"absolute",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",  
      borderRadius:"100%",
     backgroundColor:"#0080ff",
     textAlign:"center"
    }}>{text}</animated.div>
  )
}

const AnimatedText = (ref, circleRadius, leftoffset, topoffset, text, color="white") => {
  const textSpring = useSpring({from:{opacity:0}, to:{opacity:1}, config:{ duration: 500 }, ref:ref})
  return( <animated.div style={{
    ...textSpring,
    position:"absolute",
    width: 2*circleRadius,
    height: 2*circleRadius,
    borderRadius:"100%",
    top: window.innerHeight/2 + topoffset - circleRadius ,
    left:window.innerWidth/2  + leftoffset - circleRadius,
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    fontSize: window.innerWidth < 600? "10px": "20px",
    color:color,
    fontWeight:window.innerWidth < 600? 600: 800,
  }}>{text}</animated.div>)
}


function IdeaToProductDiagram() {
  const circleRadius = window.innerWidth < 600? 40: 80

  const circle1Ref = useRef()
  const circle1 = useSpring(circleConfig(-3*circleRadius, circleRadius, circle1Ref))
  
  const text1Ref = useRef()
  const text1 = AnimatedText(text1Ref, circleRadius, -3*circleRadius, 100, "Idea")

  
  const circle2Ref = useRef()
  const circle2 = useSpring(circleConfig(-1*circleRadius, circleRadius, circle2Ref))

  const text2Ref = useRef()
  const text2 = AnimatedText(text2Ref, circleRadius, -1*circleRadius, 100, "Design")

  const circle3Ref = useRef()
  const circle3 = useSpring(circleConfig(1*circleRadius, circleRadius, circle3Ref))

  const text3Ref = useRef()
  const text3 = AnimatedText(text3Ref, circleRadius, 1*circleRadius, 100, "Development")

  const circle4Ref = useRef()
  const circle4 = useSpring(circleConfig(3*circleRadius, circleRadius, circle4Ref))

  const text4Ref = useRef()
  const text4 = AnimatedText(text4Ref, circleRadius, 3*circleRadius, 100, "Product")

  const text5Ref = useRef()
  const text5 = AnimatedText(text5Ref, 4*circleRadius, 0, 100 + 1.5*circleRadius, "transform your ideas to product with us...", '#0080ff')

  useChain([circle1Ref, text1Ref, circle2Ref, text2Ref, circle3Ref, text3Ref, circle4Ref, text4Ref, text5Ref])

  return (
    <div>
      {AnimatedCircle(circle1)}
      {text1}
      {AnimatedCircle(circle2)}
      {text2}
      {AnimatedCircle(circle3)}
      {text3}
      {AnimatedCircle(circle4)}
      {text4}
      {text5}
    </div>
  )
}

export default IdeaToProductDiagram;