import React from 'react'

const item = (text, imagePath) => {
    return(
    <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom:"10px"}}>
        <img src={imagePath} width="100px" height="100px" alt={text} style={{display:"block", flexShrink:0}}/>
        <p style={{display:"block", fontSize:'1.2rem', fontWeight:700, color:"#57595d", maxWidth:"400px", marginLeft:"5px"}}>
            {text}
        </p>
    </div>
    )
}



function WhyUs(){
    return(
    <div style={{marginLeft:"20px", marginTop:"20px", display:"flex", flexDirection:"column"}}>
        {item('Fixed Cost Projects', require('../../images/fixed_cost_project.svg'))}
        {item('You completely own codebase', require('../../images/own_your_project.svg'))}
        {item('Prototype development with license free software and free deployment', require('../../images/prototype_development.svg'))}
        {item('Learn about maintaining your codebase', require('../../images/learn_with_us.svg'))}
        {item('Free Consulation', require('../../images/free_consulation.svg'))}
        {item('Continuous Review, Update and Deployment', require('../../images/continuous_review.svg'))}
    </div>)
}

export default WhyUs