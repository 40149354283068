import React from 'react'

function AboutUs(){
    return(
        <div style={{display:"block", margin:"20px", maxWidth:"800px", border:"1px solid lightgray", borderRadius:"5px", padding:"1rem"}}>
            <img src={require('../../images/profile_pic.JPG')} width="150px" height="200px" alt="Profile Pic" style={{float:"left", marginRight:"5px", marginBottom:"5px", borderRadius:"5px"}}/>
            <span style={{fontSize:"1.2rem"}}>
            Hi I m Divyendu, software engineer based in San Jose, California. 
            I have started signerd.io to help transition of engineering applications to web and mobile platforms. 
            Having worked at Bosch and Tesla in various engineering roles, I have come to appreciate the power which comes with integrating engineering applications with web.
            <br/>
            While a frontend running on browser, gives you independence to run applications on any computing platform be it Linux or Windows, 
            a supporting backend allows to scale computing resources.
            With frameworks such as Reactjs and Nodejs, you can create highly scalable and complex applications at the speed of thought for web and mobile platforms.
            <br/>
            We at signerd.io are excited about all the possibilites that come when you move your engineering application to web. 
            Looking forward to hear, how signerd.io can make your engineering ready for web!
            </span>
        </div>   
    )
}

export default AboutUs