import React, {useState, useEffect} from 'react'
import {ReactComponent as Logo} from './images/hamburger_menu.svg'
import IdeasToProductDiagram from './Components/IdeasToProduct/IdeasToProduct'
import {animated, useSpring, config} from 'react-spring'
import {useWheel} from 'react-use-gesture'
import WhatWeDo from './Components/WhatWeDo/WhatWeDo'
import CoreSkills from './Components/CoreSkills/CoreSkills'
import RecentProjects from './Components/RecentProjects/RecentProjects'
import WhyUs from './Components/WhyUs/WhyUs'
import BusinessEnquiry from './Components/BusinessEnquiry/BusinessEnquiry'
import AboutUs from './Components/AboutUs/AboutUs'
import { SvgLoader, SvgProxy } from "react-svgmt"
import ReactGA from 'react-ga'
import {Container, Row, Col, Card, ButtonGroup, Button, Table} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

let allowStateChange = true

const disableStateChange = () => {
  allowStateChange = false
  //disable state change for 200ms
  setTimeout(() => {
    allowStateChange = true
  }, 1000)
}

function App() {
  const [displayPage, setDisplayPage] = useState('ideaPage')
  const [showMenu, setShowMenu] = useState(false)

  useEffect(()=>{
    ReactGA.initialize('UA-172719227-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  })
  
  const increasingWidthIdeaPage  = useSpring({from:{width:displayPage === 'ideaPage'? 0: window.innerWidth}, to:{width: displayPage === 'ideaPage'?window.innerWidth:0}, config: {duration:5000}})

  const whatWeDoPageHeight = Math.max(window.innerHeight, 1200)
  const increasingHeightWhatWeDoPage = useSpring({from:{height:displayPage === 'whatWeDoPage'? 0: whatWeDoPageHeight}, to:{height:displayPage === 'whatWeDoPage'? whatWeDoPageHeight: 0}, config:{duration:1500}})

  const coreSkillsPageHeight = Math.max(window.innerHeight, 1000)
  const increasingHeightCoreSkillsPage  = useSpring({from:{height:displayPage === 'coreSkillsPage'? 0: coreSkillsPageHeight}, to:{height: displayPage === 'coreSkillsPage'? coreSkillsPageHeight:0}, config: {duration:1000}})

  const whyUsPageHeight = Math.max(window.innerHeight, 1000)
  const increasingHeightWhyUsPage = useSpring({from:{height:displayPage === 'whyUsPage'? 0: whyUsPageHeight}, to:{height:displayPage === 'whyUsPage'? whyUsPageHeight: 0}, config:{duration:1500}})

  const bindWheelIdeaPage = useWheel(({velocities}) => {
    const [vx, vy] = velocities
    if (vy >0.25 && allowStateChange){
      setDisplayPage('whatWeDoPage')
      disableStateChange()
    }
  })

  const bindWheelWhatWeDoPage = useWheel(({velocities}) => {
    const [vx, vy] = velocities
    if (vy < -0.25 && allowStateChange){
      setDisplayPage('ideaPage')
      disableStateChange()
    }
    if (vy > 0.25 && allowStateChange){
      setDisplayPage('coreSkillsPage')
      disableStateChange()
    }
  })


  const bindCoreSkillsPage = useWheel(({velocities}) => {
    const [vx, vy] = velocities
    if (vy < -0.25 && allowStateChange){
      setDisplayPage('whatWeDoPage')
      disableStateChange()
    }

    if (vy > 0.25 && allowStateChange){
      setDisplayPage('recentProjectsPage')
      disableStateChange()
    }
  })

  const bindRecentProjectsPage = useWheel(({velocities}) => {
    const [vx, vy] = velocities
    if (vy < -0.25 && allowStateChange){
      setDisplayPage('coreSkillsPage')
      disableStateChange()
    }

    if (vy > 0.25 && allowStateChange){
      setDisplayPage('whyUsPage')
      disableStateChange()
    }
  })

  const bindWhyUsPage = useWheel(({velocities}) => {
    const [vx, vy] = velocities
    if (vy < -0.25 && allowStateChange){
      setDisplayPage('recentProjectsPage')
      disableStateChange()
    }
    if (vy > 0.25 && allowStateChange){
      setDisplayPage('businessEnquiryPage')
      disableStateChange()
    }
  })

  const bindBusinessEnquiryPage = useWheel(({velocities}) => {
    const [vx, vy] = velocities
    if (vy < -0.5 && allowStateChange){
      setDisplayPage('whyUsPage')
      disableStateChange()
    }
    if (vy > 0.25 && allowStateChange){
      setDisplayPage('aboutUsPage')
      disableStateChange()
    }
  })

  const bindAboutUsPage = useWheel(({velocities}) => {
    const [vx, vy] = velocities
    if (vy < -0.25 && allowStateChange){
      setDisplayPage('businessEnquiryPage')
      disableStateChange()
    }
  })

  const menuButton = (color, text, fontColor) =>
  <div style={{display:"inline-block", zIndex:1000, marginLeft:"20px", marginTop:"20px"}}>
    <Button variant="outline" style={{color:color, display:"inline-block"}} onClick={()=> setShowMenu(!showMenu)}>
      <div style={{display:"flex", flexDirection:"row", alignItems:"top", marginTop:"10px"}}>
        <SvgLoader width="50" height="50" style={{marginRight:"0px", display:"inline-block"}} path={require('./images/hamburger_menu.svg')}>
          <SvgProxy selector="g" fill={color}/>
        </SvgLoader>
        <Table style={{display:"inline"}}>
          <tbody>
            <tr>
              <td style={{display:"inline-block", fontSize:"1.2rem", fontWeight:600, color:color, padding:"3px"}}>
                signerd.io>
              </td>
            </tr>
            <tr>
              <td style={{display:"inline-block", fontSize:"1.2rem", fontWeight:600, color:color, padding:"3px"}}>
                {text}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <ButtonGroup style={{display:showMenu?'flex':'none', flexDirection:"column", zIndex:1000}}>
        <Button variant="primary" style={{margin:"1px", backgroundColor:color, color:fontColor}} onClick={()=>setDisplayPage('whatWeDoPage')}>What we do</Button>
        <Button variant="primary" style={{margin:"1px", backgroundColor:color, color:fontColor}} onClick={()=>setDisplayPage('coreSkillsPage')}>Core skills</Button>
        <Button variant="primary" style={{margin:"1px", backgroundColor:color, color:fontColor}} onClick={()=>setDisplayPage('recentProjectsPage')}>Recent Projects</Button>
        <Button variant="primary" style={{margin:"1px", backgroundColor:color, color:fontColor}} onClick={()=>setDisplayPage('whyUsPage')}>Partner with us?</Button>
        <Button variant="primary" style={{margin:"1px", backgroundColor:color, color:fontColor}} onClick={()=>setDisplayPage('businessEnquiryPage')}>Contact Us</Button>
        <Button variant="primary" style={{margin:"1px", backgroundColor:color, color:fontColor}} onClick={()=>setDisplayPage('aboutUsPage')}>About Us</Button>
      </ButtonGroup>
    </Button>
  </div>



  

  if (displayPage === 'ideaPage'){
    return(
      <animated.div style={{height:"100vh", width:increasingWidthIdeaPage.width, backgroundColor:"white", position:"absolute", top:0, left:0}}>
        {menuButton("#0080ff", '', 'white')}
        <IdeasToProductDiagram/>
    </animated.div>
    )
  }
  else if (displayPage === 'whatWeDoPage'){
    return(
      <animated.div style={{width:"100vw", height:increasingHeightWhatWeDoPage.height, backgroundColor:"gold", position:"absolute", top:"0", left:"0"}}>
        <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
          {menuButton("#0080ff", 'What we do', 'gold')}
          <WhatWeDo/>
        </div>      
      </animated.div>
    )
  }
  else if (displayPage === 'coreSkillsPage'){
    return(
      <animated.div style={{width:"100vw", height:increasingHeightCoreSkillsPage.height, backgroundColor:"#57595d", position:"absolute", top:0, left:0}}>
          <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
            {menuButton("gold", 'Core Skills','#57595d')}
            <CoreSkills/>
          </div>
      </animated.div>
    )
  }
  else if (displayPage === 'recentProjectsPage'){
    return(
      <animated.div style={{height:"100%", width:"100vw", position:"absolute", backgroundColor:"white", top:0, left:0, marginBottom:"20px"}}>
        <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
          {menuButton("#0080ff", 'Recent projects', 'white')}
          <RecentProjects/>
        </div>
      </animated.div>
       
    )
  }
  else if (displayPage === 'whyUsPage'){
    return(
      <animated.div style={{width:"100vw", height:increasingHeightWhyUsPage.height, backgroundColor:"gold", color:"#57595d", position:"absolute", top:0, left:0}}>
        <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
          {menuButton("#57595d", "Partner with us?", 'gold')}
          <WhyUs/>
        </div>
      </animated.div>
    )
  }
  else if (displayPage === 'businessEnquiryPage'){
    return(
      <animated.div style={{width:"100vw", height:"100vh", backgroundColor:"white"}}>
        <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
          {menuButton("#0080ff", 'Contact Us', 'white')}
          <BusinessEnquiry/>
        </div>
      </animated.div>
    )
  }
  else{
    return(
      <animated.div style={{width:"100vw", height:"100vh", backgroundColor:"white"}}>
        <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
          {menuButton("#0080ff", "About us", 'white')}
          <AboutUs/>
        </div>
      </animated.div>
    )
  }
}

export default App;